<template>
    <card title="Services" class="border-radius overflow-hidden mt-8" body-class="-mx-6 overflow-visible" title-no-border>

        <div slot="tools" class="relative flex ml-auto align-right tools items-center">
            <div style="min-width: 300px; visibility: hidden"></div>
            <icon name="icons/gear" key="carrier-services-gear" ref="toggleTableToolsBtn" class="ml-4" @click="toggleTableTools = !toggleTableTools" />
            <transition name="slide">
                <div
                    v-if="toggleTableTools"
                    class="absolute right-0 p-2 -mr-3 border rounded arrow-box dropdown top-8 z-10 cs-dropdown"
                    v-closable="{
                        exclude: ['toggleTableToolsBtn'],
                        handler: 'onClose'
                    }"
                >
                    <h5 class="pb-2 pl-2 text-gray-700 border-b">Table settings</h5>
                    <ul>
                        <li v-for="(col, index) in columns" v-if="!col.hidden" :key="index">
                            <label class="flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    class="form-checkbox"
                                    v-model="col.visible"
                                    @change="saveColumns"
                                />
                                <span class="ml-2">{{ col.title }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>

        <div class="w-full">
            <transition name="fade">
                <modal v-if="showModal" @hide="showModal = false" :value="serviceValue" :index="serviceIndex" @update="updateModal" />
            </transition>

            <div v-if="isDataLoaded" class="cs-table overflow-auto">
                <table class="table border-b">
                    <tr class="thead table-thead-service">
                        <th v-for="column in columns" v-if="isColumnVisible(column.key)" class="w-48 border-b-2 pl-3">{{ column.title }}</th>
                        <!-- <th class="w-1/9 border-b-2 text-right">Actions</th> -->
                    </tr>
                    <!-- <tr>
                        <td
                            v-for="column in columns"
                            v-show="isColumnVisible(column.key)"
                            class="w-1/9">
                            <template v-if="column.hasMasterInput">
                                <div class="flex flex-col w-20">
                                    <the-mask
                                        mask="######"
                                        v-model="masterInputsModel[column.key]"
                                        @input="changeColumnValues(column.key)"/>
                                </div>
                            </template>
                        </td>
                    </tr> -->
                    <tr
                        v-for="(service, index) in manageServicesModel"
                        :class="[`td-service-id__${service.id}`]"
                        class="td-service"
                    >
                        <td v-if="isColumnVisible('name')">
                            <div class="w-48">
                                <t-input :placeholder="getColumnTitle('name')" v-model="service.name" :error="hasError(index, 'name')"/>
                                <detectors :value="service.detectors" class="mt-2" />
                            </div>
                        </td>
                        <td v-if="isColumnVisible('service_settings')">
                            <div class="flex flex-col pl-3 w-48">
                                <div v-if="service.transport_mode" class="flex">
                                    <span class="text-gray-700 mr-1">Transport mode: </span>
                                    {{ service.transport_mode }}
                                </div>
                            </div>
                        </td>
                        <td v-if="isColumnVisible('is_time_definite')">
                            <div class="pl-3 w-48">
                                <div class="flex">
                                    <checkbox 
                                        v-model="service.is_time_definite" 
                                        :checked="Boolean(service.is_time_definite)" 
                                        :key="index"
                                    >
                                        <span v-text="getColumnTitle('is_time_definite')" />
                                    </checkbox>
                                </div>
                            </div>
                        </td>
                        <td v-if="isColumnVisible('consignor_code')">
                            <div class="pl-3 w-48">
                                <t-input :placeholder="getColumnTitle('consignor_code')" v-model="service.consignor_code" :error="hasError(index, 'consignor_code')"/>
                            </div>
                        </td>
                        <td v-if="isColumnVisible('consignor_code_dutiable')">
                            <div class="pl-3 w-48">
                                <t-input :placeholder="getColumnTitle('consignor_code_dutiable')" v-model="service.consignor_code_dutiable" :error="hasError(index, 'consignor_code_dutiable')"/>
                            </div>
                        </td>
                        <td v-if="isColumnVisible('transport_mode')">
                            <div class="pl-3 w-48">
                                <modal-field>
                                    <multi-select v-model="service.transport_mode"  class="carrier-service-multiselect" :placeholder="getColumnTitle('transport_mode')" :branding="false" :error="hasError(index, 'transport_mode')" :options="transportModes"></multi-select>
                                </modal-field>
                            </div>
                        </td>
                        <td v-if="isColumnVisible('variations')">
                            <div class="pl-3 w-48">
                                <modal-field>
                                    <multi-select v-model="service.variations" :placeholder="getColumnTitle('variations')" :branding="false" :error="hasError(index, 'variations')" :dynamic="true" :multiple="true" :options="[]"></multi-select>
                                </modal-field>
                            </div>
                        </td>
                        <td v-if="isColumnVisible('wait_hours_before_no_progress_in_pre_transport')">
                            <div class="flex flex-col pl-3 w-48">
                                <the-mask
                                    mask="######"
                                    :placeholder="getColumnTitle('wait_hours_before_no_progress_in_pre_transport')"
                                    v-model="service.wait_hours_before_no_progress_in_pre_transport"
                                    :error="hasError(index, 'wait_hours_before_no_progress_in_pre_transport')"/>
                            </div>
                        </td>
                        <td v-if="isColumnVisible('wait_hours_before_no_progress_in_pre_transport_daily_pick_up')">
                            <div class="flex flex-col pl-3 w-48">
                                <the-mask
                                    mask="######"
                                    :placeholder="getColumnTitle('wait_hours_before_no_progress_in_pre_transport_daily_pick_up')"
                                    v-model="service.wait_hours_before_no_progress_in_pre_transport_daily_pick_up"
                                    :error="hasError(index, 'wait_hours_before_no_progress_in_pre_transport_daily_pick_up')"/>
                            </div>
                        </td>
                        <td v-if="isColumnVisible('wait_hours_before_no_progress_in_in_transit')">
                            <div class="flex flex-col pl-3 w-48">
                                <the-mask
                                    mask="######"
                                    :placeholder="getColumnTitle('wait_hours_before_no_progress_in_in_transit')"
                                    v-model="service.wait_hours_before_no_progress_in_in_transit"
                                    :error="hasError(index, 'wait_hours_before_no_progress_in_in_transit')"/>
                            </div>
                        </td>
                        <td v-if="isColumnVisible('wait_hours_before_no_progress_in_reception')">
                            <div class="flex flex-col pl-3 w-48">
                                <the-mask
                                    mask="######"
                                    :placeholder="getColumnTitle('wait_hours_before_no_progress_in_reception')"
                                    v-model="service.wait_hours_before_no_progress_in_reception"
                                    :error="hasError(index, 'wait_hours_before_no_progress_in_reception')"/>
                            </div>
                        </td>
                        <td v-if="isColumnVisible('code')">
                            <div class="pl-3 w-48">
                                <t-input :placeholder="getColumnTitle('code')" v-model="service.code" :error="hasError(index, 'code')"/>
                            </div>
                        </td>
                        <td v-if="isColumnVisible('tags')">
                            <div class="pl-3 cs-multi-code w-48">
                                <multiselect
                                    v-model="service.tags"
                                    :branding="false"
                                    :options="service.tagsOptions"
                                    taggable
                                    multiple
                                    tag-placeholder="Add this as new tag"
                                    placeholder="Tags (emissions)"
                                    label="label"
                                    track-by="label"
                                    open-direction="bottom"
                                    @tag="addTag($event, service)"
                                />
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <div v-else class="flex items-center justify-center">
                <div class="flex flex-col justify-center text-gray-500 my-8">
                    <fa :icon="['fal','box-open']" class="mx-auto text-7xl"/>
                    <p class="mt-5">No services have been added yet</p>
                </div>
            </div>

            <buttons @addNewRow="addServiceRow" @save="save"></buttons>
        </div>
    </card>
</template>

<script>
import axios from 'axios';
import Buttons from './Buttons';
import Modal from './Modal.vue';
import VCheckbox from '~/components/VCheckbox.vue';
import Detectors from './Detectors';

const rulesFieldNames = {
    inTransit: 'wait_hours_before_no_progress_in_in_transit',
    preTransport: 'wait_hours_before_no_progress_in_pre_transport',
    preTransportDailyPickUp: 'wait_hours_before_no_progress_in_pre_transport_daily_pick_up',
    reception: 'wait_hours_before_no_progress_in_reception',
}

export default {
    name: 'CarrierServices',
    components: {
        Buttons,
        Modal,
        VCheckbox,
        Detectors,
    },
    props: {
        carrier: {
            required: true,
            type: Object,
        },
    },
    data: () => ({
        showModal: false,
        serviceValue: {},
        serviceIndex: 0,
        masterInputsModel: {},
        toggleTableTools: false,
        serviceValidationErrors: {},
        transportModes: ['Air', 'Ocean', 'Road', 'Courier', 'Rail', 'Express'],
        serviceTemplate: {
            name: '',
            code: '',
            daily_pickup: false,
            is_time_definite: 0,
            transport_mode: '',
            consignor_code: '',
            consignor_code_dutiable: '',
            variations: [],
            wait_hours_before_no_progress_in_in_transit: null,
            wait_hours_before_no_progress_in_pre_transport: null,
            wait_hours_before_no_progress_in_pre_transport_daily_pick_up: null,
            wait_hours_before_no_progress_in_reception: null,
        },
        columns: [
            {
                title: 'Name',
                key: 'name',
                visible: true,
                hasMasterInput: false,
            },
            {
                title: 'Service settings',
                key: 'service_settings',
                visible: true,
            },
            {
                title: 'Time-definite',
                key: 'is_time_definite',
                visible: true,
                hasMasterInput: false,
            },
            {
                title: 'Consignor code',
                key: 'consignor_code',
                visible: false,
                hasMasterInput: false,
            },
            {
                title: 'Consignor code (dutiable)',
                key: 'consignor_code_dutiable',
                visible: false,
                hasMasterInput: false,
            },
            {
                title: 'Transport mode',
                key: 'transport_mode',
                visible: false,
                hasMasterInput: false,
            },
            {
                title: 'Variations',
                key: 'variations',
                visible: false,
                hasMasterInput: false,
                hidden: true,
            },
            {
                title: 'Pre transport -> No progress, h',
                key: 'wait_hours_before_no_progress_in_pre_transport',
                visible: true,
                hasMasterInput: true,
            },
            {
                title: 'Pre transport (daily pickup) -> No progress, h',
                key: 'wait_hours_before_no_progress_in_pre_transport_daily_pick_up',
                visible: true,
                hasMasterInput: true,
            },
            {
                title: 'In transit -> No progress, h',
                key: 'wait_hours_before_no_progress_in_in_transit',
                visible: true,
                hasMasterInput: true,
            },
            {
                title: 'Reception -> No progress, h',
                key: 'wait_hours_before_no_progress_in_reception',
                visible: true,
                hasMasterInput: true,
            },
            {
                title: 'Tariff code',
                key: 'code',
                visible: true,
                hasMasterInput: false,
            },
            {
                title: 'Tags (emissions)',
                key: 'tags',
                visible: true,
                hasMasterInput: false,
            }
        ],
    }),
    methods: {
        addTag(tag, service) {
            let tagIndex = service.tags.findIndex(serviceTag => serviceTag.label === tag);
            
            if (tagIndex !== -1) {
                service.tags.splice(tagIndex, 1);

                return;
            }

            tagIndex = service.tagsOptions.findIndex(optionTag => optionTag.label === tag);

            if (tagIndex !== -1) {
                service.tags.push({ isTag: true, label: tag });

                return;
            }

            const newTag = { isTag: true, label: tag };

            service.tags.push(newTag);
            service.tagsOptions.push(newTag);
        },
        onClose() {
            this.toggleTableTools = false
            this.$emit('hide')
        },
        updateModal(value) {
            this.carrier.services.forEach(item => {
                if (value.index === item.id) {
                    item.transport_mode = value.value.transport_mode;
                }
            });
        },
        openModal(value, index) {
            this.serviceValue = value;
            this.serviceIndex = index;
            this.showModal = true;
        },
        getServices() {
            // if (this.carrier.services.rules) {
            //     return this.carrier.services;
            // }
            for (let i = 0; i < this.carrier.services.length; ++i) {
                let service = this.carrier.services[i];
                service = { ...service, ...this.getCarrierRules(service.id) };
            }
            return this.carrier.services;
        },
        getCarrierRules(carrierServiceId) {
            for (let i = 0; i < this.carrier.services; ++i) {
                if (this.carrier.services[i].id === carrierServiceId) {
                    const service = this.carrier.services[i];

                    return {
                        [rulesFieldNames.inTransit]: service[rulesFieldNames.inTransit],
                        [rulesFieldNames.preTransport]: service[rulesFieldNames.preTransport],
                        [rulesFieldNames.preTransportDailyPickUp]: service[rulesFieldNames.preTransportDailyPickUp],
                        [rulesFieldNames.reception]: service[rulesFieldNames.reception],
                    };
                }
            }

            const clonedServiceTemplate = this.cloneObject(this.serviceTemplate);

            return {
                [rulesFieldNames.inTransit]: clonedServiceTemplate[rulesFieldNames.inTransit],
                [rulesFieldNames.preTransport]: clonedServiceTemplate[rulesFieldNames.preTransport],
                [rulesFieldNames.preTransportDailyPickUp]: clonedServiceTemplate[rulesFieldNames.preTransportDailyPickUp],
                [rulesFieldNames.reception]: clonedServiceTemplate[rulesFieldNames.reception],
            };
        },
        addServiceRow() {
            const template = this.cloneObject(this.serviceTemplate);
            this.manageServicesModel.push(template);
        },
        deleteServiceRow(index) {
            this.manageServicesModel.splice(index, 1);
        },
        changeColumnValues(columnKey) {
            let masterValue = this.masterInputsModel[columnKey];

            for (let i = 0; i < this.manageServicesModel.length; ++i) {
                let service = this.manageServicesModel[i];

                if (service.hasOwnProperty(columnKey)) {
                    service[columnKey] = masterValue;
                } else {
                    service[columnKey] = masterValue;
                }
            }
        },
        save() {
            const data = {
                services: this.cloneObject(this.manageServicesModel).map(item => {
                    return Object.keys(item).reduce((output, key) => {
                        if (key === 'tags') {
                            output[key] = item.tags.map(tag => tag.label);

                            return output;

                        }

                        if (key === 'detectors' || key === 'tagsOptions') {
                            return output;
                        }

                        output[key] = item[key];

                        return output;
                    }, {});
                })
            };

            axios.post(`${this.$apiUrl.carriers.base}/${this.carrier.id}/services`, data).then(() => {
                this.resetValidationErrors();
                this.$snotify.success('Carrier services updated!');
            }).catch((error) => {
                this.serviceValidationErrors = error.response.data.errors;
            });
        },
        hasError(index, valueKey) {
            const validationKey = `services.${index}.${valueKey}`;

            if (this.serviceValidationErrors[validationKey]) {
                return this.serviceValidationErrors[validationKey];
            }

            return false;
        },
        resetValidationErrors() {
            this.serviceValidationErrors = {};
        },
        getColumnTitle(key) {
            return this.columns.find(column => column.key === key).title;
        },
        isColumnVisible(key) {
            if (!key) {
                return false;
            }
            return this.columns.find(column => column.key === key).visible;
        },
        saveColumns() {
            const columns = JSON.stringify(this.columns);

            localStorage.setItem('carriers-services-columns', columns);
        },

        initColumns() {
            const columns = JSON.parse(localStorage.getItem('carriers-services-columns'));

            if (!columns) {
                return;
            }

            this.columns = columns;
        },

        scrollToCarrierServiceId() {
            const id = this.$route.query.carrier_service_id;

            if (!id) {
                return;
            }

            if (!this.carrier.services.length) {
                setTimeout(() => {
                    this.scrollToCarrierServiceId();
                }, 100);

                return;
            }

            const dom = document.querySelector(`.td-service-id__${id}`);

            if (!dom) {
                return;
            }

            const top = dom.getBoundingClientRect().top;
            const theadHeight = document.querySelector('.table-thead-service').clientHeight;

            window.scrollTo({
                top: (top + window.scrollY) - theadHeight,
                behavior: 'smooth',
            });

            dom.classList.add('td-service__active');

            setTimeout(() => {
                dom.classList.remove('td-service__active');
            }, 3000);
        },
    },
    computed: {
        isDataLoaded() {
            return this.carrier.services.length > 0;
        },
        manageServicesModel: {
            set(service) {
                this.carrier.services.push(service);
            },
            get() {
                return this.getServices();
            }
        },
    },

    async mounted() {
        await this.initColumns();
        await this.scrollToCarrierServiceId();
    },
};

</script>

<style lang="scss" scoped>
.thead {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 49;
    background-color: white;
}

.cs-multi-code {
    max-width: 220px;
    min-width: 220px;
}

.cs-table {

    @media screen and (max-width: 1365px) {
        overflow: auto;
    }

    table td:first-child, table th:first-child {
        & > div {
            padding-left: 0 !important;
        }
    }
}

.cs-dropdown {
    z-index: 101 !important;
}

.td-service__active {
    background-color: #fffce1;
}

.td-service {
    transition: background 1s;
}
</style>
